<script>
import Button from '../components/Button';
import WannaMilk from '../assets/img/wanna-milk.svg';

export default {
  name: 'Disclaimer',
  components: { Button },
  data: () => ({
    WannaMilk,
  }),
};
</script>

<template>
  <div class="partnership">
    <div class="container">
      <img :src="WannaMilk" class="hide-description"/>
      <h1 class="title">{{ $t('partnership.title') }}</h1>
      <div class="list">
        <ul>
          <li class="item" v-for="(text, key) in $t('partnership.question')" :key="key">
            {{ text }}
          </li>
        </ul>
      </div>
      <p class="join">{{ $t('partnership.join') }}</p>
      <div class="description">
        <p v-for="(text, key) in $t('partnership.descriptions')" :key="key" v-html="text"></p>
      </div>
      <Button :to="{ name: 'index' }">
        {{ $t('partnership.button') }}
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .partnership {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .container {
      margin: 2rem auto;
      max-width: 65rem;
      width: calc(100vw - 4rem);
      padding: 0 2rem;
      margin-bottom: 50px;
      text-align: center;

      @media (min-width: 856px) {
        margin-bottom: 120px;
      }

      @media (min-width: 1400px) {
        margin-bottom: 140px;
      }
    }

    .title {
      max-width: 650px;
      margin: 0 auto;
      margin-bottom: 30px;
      color: #000E89;
      font-weight: bold;
      font-size: 34px;
    }

    .btn {
      margin: 0 auto;
    }

    .description {
      color: #000E89;
      margin-top: 30px;
      margin-bottom: 60px;
      line-height: 1.5;
      font-size: 16px;
      text-align: left;
    }

    .hide-description {
      max-width: 1170px;
      background-size: contain;
      width: 90%;
      height: 140px;
      position: relative;
      bottom: -70px;
    }
    .list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .item {
        width: auto;

        font-size: 20px;
        font-weight: 600;
        color: #000E89;
      }
    }
    .join {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #000E89;
    }
    body.binance &{
      .title, .item, .join, .description{
        color: white;
      }
    }
    body.avalanche &{
      .title, .item, .join, .description{
        color: black;
      }
    }
  }
</style>
